<template>
    <div class="col-sm-12">
        <button class="margin_bottom" @click="loadEmails"><span class="glyphicon glyphicon-refresh"></span></button>
        <table class="table table-condensed">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Subject</th>
                    <th>Status</th>
                    <th>Attachments</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="email in emails" :key="email.cdi_email_id">
                    <td>{{ email.cdi_email_id }}</td>
                    <td>{{ email.email_from }}</td>
                    <td>{{ email.email_to }}</td>
                    <td>{{ email.subject }}</td>
                    <td>{{ email.send_error.slice(0, 100) }}</td>
                    <td v-html="parseCSV(email.attachments)"></td>
                    <td><button class="btn btn-default" @click="sendEmail(email.cdi_email_id)"><span class="glyphicon-envelope glyphicon"></span></button></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

import { store } from '@/store/Tool.store';

export default {
    name: 'DevEmail',
    methods: {
        loadEmails: function() {
            store.api('/dev_tools/load_emails', '').then( (data) => {
                this.emails = data.emails
            })
        },
        sendEmail: function(email_id) {
            store.api('/dev_tools/send_email/' + email_id, '').then( (data) => {
                this.emails = data.emails
            })
        },
        parseCSV: function(string) {
            if (string) {
                return string.replaceAll(',', '<br />')
            } else {
                return ''
            }
        }
    },
    data() {
        return {
            state: store.state,
            emails: []
        }
    },
    created() {
        this.loadEmails();
    }
}

</script>

<style scoped>

</style>