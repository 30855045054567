<template>
    <div class="col-sm-12">
        <div class="alert alert-info margin_bottom">This report is incomplete due to data insertion issues on Wells Fargo import. Will be resolved in a future patch.</div>
        <div class="col-sm-9">
            <DatePicker :input-attributes="{ class: 'form-control'}" v-model="importDate" />
            <div class="col-sm-6">
                <div class="radio">
                    <label>
                        <input type="radio" name="optionsRadios" v-model="dataFormat" value="raw">
                        Raw
                    </label>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="radio">
                    <label>
                        <input type="radio" v-model="dataFormat" name="dataFormat" value="pretty">
                        Pretty
                    </label>
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <button class="margin_bottom btn btn-default btn-block" @click="getWellsFargoImports">Search</button>
        </div>

        <div class="col-sm-12">
            <table v-show="dataFormat === 'raw'" class="table table-striped table-responsive table-condensed">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Import Date</th>
                    <th>Company ID</th>
                    <th>Payment Source</th>
                    <th>Payment Date</th>
                    <th>Check Number</th>
                    <th>Amount</th>
                    <th>Invoice Number</th>
                    <th>Gross Invoice Amount</th>
                    <th>Net Invoice Amount</th>
                    <th>Discount Amount</th>
                    <th>Dade ID</th>
                    <th>Comments</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="wf in imports" :key="wf.wells_fargo_import_id">
                    <td>{{ wf.wells_fargo_import_id }}</td>
                    <td>{{ wf.imported_date }}</td>
                    <td>{{ wf.company_id }}</td>
                    <td>{{ wf.payment_src }}</td>
                    <td>{{ wf.payment_date }}</td>
                    <td>{{ wf.check_number }}</td>
                    <td>{{ wf.amount }}</td>
                    <td>{{ wf.invoice_number }}</td>
                    <td>{{ wf.gross_invoice_amt }}</td>
                    <td>{{ wf.net_invoice_amt }}</td>
                    <td>{{ wf.discount_amt }}</td>
                    <td>{{ wf.dade_id }}</td>
                    <td>{{ wf.comments }}</td>
                </tr>
                </tbody>
            </table>

            <div v-show="dataFormat === 'pretty'">
                <div class="panel panel-default">
                    <div class="panel-heading">Panel heading</div>
                    <div class="panel-body">
                        <p>...</p>
                    </div>

                    <!-- Table -->
                    <table class="table table-condensed ">
                        ...
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import { store } from '@/store/Tool.store';
import DatePicker from "@/components/utils/DatePicker";

export default {
    name: 'DevEmail',
    components: {DatePicker},
    methods: {
        getWellsFargoImports: function() {
            store.api('/dev_tools/load_wf_imports', {
                'params[import_date]' : this.importDate
            }).then( (data) => {
                this.imports = data.imports
            })
        },
    },
    data() {
        return {
            importDate: new Date().toLocaleDateString('en'),
            dataFormat: 'raw',
            imports: []
        }
    },
    created() {

    }
}

</script>

<style scoped>

</style>